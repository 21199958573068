import type { Member } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/types';
import { BLOG_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { ROLE_WRITER } from '@wix/communities-blog-universal/dist/src/permissions-checker-constants';

export const getUserRoleByApp = (
  user: Member | undefined,
  appDefinitionId: string,
) => user?.appStore?.[appDefinitionId]?.permissions?.role;
export const getUserRole = (user: Member | undefined) =>
  getUserRoleByApp(user, BLOG_APP_ID);
export const isWriter = (user: Member | undefined) =>
  getUserRole(user) === ROLE_WRITER;
